const templates = [
  {
    name: "Prawda/Fałsz (x2 - tabela)",
    exerciseTitle:
      '<p>Tutaj wpisz informacje o zadaniu Pamiętaj o zaznaczeniu poprawnej odpowiedzi w edytorze kodu.</p>\n<p><strong>Oceń prawdziwość podanych zdań. Wybierz P, jeśli zdanie jest prawdziwe, albo F – jeśli\n    jest fałszywe.</strong></p>\n<table class="exercise-table">\n    <tbody>\n        <tr>\n            <td>Teza 1</td>\n            <td class="answer">P</td>\n            <td class="answer">F</td>\n        </tr>\n        <tr>\n            <td>Teza 2<br></td>\n            <td class="answer">P</td>\n\n            <td class="answer">F</td>\n        </tr>\n    </tbody>\n</table>',
    exerciseType: "select",
    id: 0,
    exerciseAnswers: [
      {
        content: "PP",
        isCorrect: true,
      },
      {
        content: "PF",
        isCorrect: false,
      },
      {
        content: "FF",
        isCorrect: false,
      },
      {
        content: "FP",
        isCorrect: false,
      },
    ],
  },
  {
    name: "T/N, ponieważ A/B/C",
    exerciseTitle:
      '<p>Tutaj wpisz dane do zadania. Pamiętaj, aby zmienić domyślną odpowiedź w edytorze kodu.<br></p>\n<p><strong>Wybierz odpowiedź T albo N i jej uzasadnienie spośród A, B albo C.</strong></p>\n<table class="padding-table">\n    <tbody>\n        \n        <tr>\n            <td style="vertical-align: middle;">\n                <p>T. Tak,</p>\n                <p>N. Nie,</p>\n            </td>\n            <td style="text-align: center; vertical-align: middle;">ponieważ</td>\n            <td style="vertical-align: middle;">\n                <p>A. argument</p>\n                <p>B. argument</p>\n                <p>C. argume</p>\n            </td>\n        </tr>\n    </tbody>\n</table>',
    exerciseType: "select",
    id: 1,
    exerciseAnswers: [
      {
        content: "<p>TA</p>",
        isCorrect: true,
      },
      {
        content: "<p>TB</p>",
        isCorrect: false,
      },
      {
        content: "<p>TC</p>",
        isCorrect: false,
      },
      {
        content: "<p>NA</p>",
        isCorrect: false,
      },
      {
        content: "<p>NB</p>",
        isCorrect: false,
      },
      {
        content: "<p>NC</p>",
        isCorrect: false,
      },
    ],
  },
  {
    name: "Prawda/Fałsz (x1)",
    exerciseTitle:
      "<p>Tutaj wpisz dane do zadania. Pamiętaj, aby zmienić domyślną odpowiedź w edytorze kodu.</p>",
    exerciseType: "select",
    id: 2,
    exerciseAnswers: [
      {
        content: "Prawda",
        isCorrect: true,
      },
      {
        content: "Fałsz",
        isCorrect: false,
      },
    ],
  },
];

export default templates;
