<template>
  <v-container>
    <v-dialog v-model="creatorWelcomeDialog" width="800" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Witamy w kreatorze!
        </v-card-title>

        <v-card-text class="mt-3">
          Wybierz plik z dysku lub rozpocznij nowy projekt.
          <input
            type="file"
            @change="getDataFromUploadedJson"
            ref="jsonFile"
            style="display: none;"
          />
          <v-row class="mt-3">
            <v-col
              ><v-btn
                block
                elevation="0"
                x-large
                color="primary"
                @click="creatorWelcomeDialog = false"
                >Rozpocznij nowy projekt</v-btn
              ></v-col
            >
            <v-col
              ><v-btn
                @click="$refs.jsonFile.click()"
                block
                elevation="0"
                x-large
                outlined
                >Wybierz plik z dysku</v-btn
              ></v-col
            >
          </v-row>
        </v-card-text>
      </v-card></v-dialog
    >

    <v-snackbar
      style="z-index: 1000000"
      v-model="waitSnackbar"
      transition="slide-y-transition"
      :top="true"
      color="info"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-information-outline</v-icon>
        <v-layout column>
          <div>
            <strong>Czekaj...</strong>
          </div>
          <div>Wykonywanie operacji...</div>
          <div>Kod ćwiczenia: {{ code }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-snackbar
      v-model="successSnackbar"
      timeout="30000"
      transition="slide-y-transition"
      :top="true"
      color="success"
      style="z-index: 1000000"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle-outline</v-icon>
        <v-layout column>
          <div>
            <strong>Zapisano!</strong>
          </div>
          <div>
            Wszystko poszło pomyślnie. Zaleca się zamknięcie wszystkich kart
            przeglądarki.
          </div>
          <div>Kod ćwiczenia: {{ code }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-card class="fixed">
      <v-card-text>
        <!-- Dialogs -->
        <AddClosedExercise :show="showOnceDialog" @finish="reRenderMath" />
        <AddInfo class="ml-2" @finish="reRenderMath" />
        <v-btn class="ml-2" @click="nextCard">Następna karta</v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" class="ml-2"> Cofnij </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="deleteLatestCard">
              <v-list-item-title>Usuń ostatnią kartę</v-list-item-title>
            </v-list-item>

            <v-list-item link @click="deleteLatestExercise">
              <v-list-item-title>Usuń ostatnie ćwiczenie</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" class="ml-2"> Zapisz </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="downloadJson">
              <v-list-item-title>Zapisz na komputerze</v-list-item-title>
            </v-list-item>

            <v-list-item link @click="publishDialog = true">
              <v-list-item-title>Publikuj</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>
    </v-card>
    <div class="xlmargin">
      <div v-for="card in cards" :key="card.nr">
        <div v-for="exercise in card.exercises" :key="exercise.id">
          <v-card
            class="mt-2 border-card"
            elevation="0"
            v-if="exercise.exerciseType == 'info'"
          >
            <v-card-title>Informacja do zadań</v-card-title>
            <v-card-text v-html="exercise.exerciseTitle"> </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="startExerciseCodeEditing(card.nr, exercise.id)"
              >
                Edytuj
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="mt-2 border-card" elevation="0" v-else>
            <v-card-title>Zadanie</v-card-title>
            <v-card-text>
              <div v-html="exercise.exerciseTitle"></div>

              <v-list v-if="exercise.exerciseType != 'open'">
                <v-list-item
                  v-for="(answer, index) in exercise.exerciseAnswers"
                  :key="index"
                >
                  <v-list-item-icon>
                    <v-icon class="ml-2 green--text" v-if="answer.isCorrect">
                      mdi-check-circle
                    </v-icon>
                    <v-icon class="ml-2 red--text" v-if="!answer.isCorrect">
                      mdi-cancel
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    v-html="answer.content"
                  ></v-list-item-content>
                </v-list-item>
              </v-list>

              <div v-else>
                <v-card color="grey lighten-4" elevation="0">
                  <v-card-title>Klucz</v-card-title>
                  <v-card-text>
                    <p v-html="exercise.exerciseAnswers"></p>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="startExerciseCodeEditing(card.nr, exercise.id)"
              >
                Edytuj
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <v-card color="grey darken-2" class="mt-2 text-center" elevation="0">
          <v-card-text class="white--text"
            >- - - NASTĘPNA KARTA - - -</v-card-text
          >
        </v-card>
      </div>
    </div>

    <v-dialog v-model="publishDialog" width="700">
      <v-stepper v-model="publishStep">
        <v-stepper-header>
          <v-stepper-step :complete="publishStep > 1" step="1">
            Podstawowe dane
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="publishStep > 2" step="2">
            Wybieranie tygodnia
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> Podsumowanie </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-text-field outlined v-model="chosenTitle" :label="`Tytuł`" />
            <v-text-field
              outlined
              v-model="chosenSubtitle"
              :label="`Podtytuł`"
            />

            <v-btn color="primary" @click="publishStep = 2"> Dalej </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-radio-group v-model="chosenWeek">
              <v-radio
                v-for="week in weeks"
                :key="week"
                :label="week"
                :value="week"
              ></v-radio>
            </v-radio-group>

            <v-btn color="primary" @click="publishStep = 3"> Dalej </v-btn>

            <v-btn text @click="publishStep = 1"> Wstecz </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-alert type="error" class="mt-2">
              <b>Uwaga:</b> Publikacji nie można cofnąć!
            </v-alert>

            <div>
              <v-list flat subheader three-line>
                <v-list-item-group multiple active-class="">
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ chosenTitle }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        chosenSubtitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>

            <p>Zostanie dodane do tygodnia: {{ chosenWeek }}</p>

            <v-btn color="primary" @click="publish"> Publikuj </v-btn>

            <v-btn text @click="publishStep = 2"> Wstecz </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>

    <v-dialog v-model="editCodeDialog" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Edytor kodu ćwiczenia
        </v-card-title>

        <v-card-text class="mt-3">
          <code-editor
            :value="editCodeDialogCode"
            @input="(val) => (editCodeDialogCode = val)"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveCodeDialog">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import AddClosedExercise from "../components/Creator/AddExercise.vue";
import AddInfo from "../components/Creator/AddInfo.vue";
import db from "@/firebase/init";
import firebase from "firebase";
import CodeEditor from "../components/Creator/CodeEditor.vue";
import download from "downloadjs";

export default {
  methods: {
    ...mapMutations([
      "changeNavVisibility",
      "pushCreatorCard",
      "deleteLatestCard",
      "deleteLatestExercise",
      "replaceCreatorExercise",
      "replaceCards",
      "creatorAutoFixUp",
    ]),
    nextCard() {
      this.pushCreatorCard();
    },
    reRenderMath() {
      this.$nextTick(() => {
        if (window.MathJax) {
          console.log("rendering mathjax");
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
            console.log("done")
          );
        }
      });
    },
    startExerciseCodeEditing(card, exercise) {
      const exerciseIndex =
        card > 0
          ? exercise -
            this.cards[card - 1].exercises[
              this.cards[card - 1].exercises.length - 1
            ].id -
            1
          : exercise;
      this.editCodeDialogCode = JSON.stringify(
        this.cards[card].exercises[exerciseIndex],
        null,
        4
      );
      this.editCodeDialog = true;
      this.editCodeDialogCardId = card;
      this.editCodeDialogExerciseIndex = exerciseIndex;
    },
    saveCodeDialog() {
      this.replaceCreatorExercise({
        cardID: this.editCodeDialogCardId,
        exerciseIndex: this.editCodeDialogExerciseIndex,
        exercise: JSON.parse(this.editCodeDialogCode),
      });
      this.editCodeDialog = false;
      this.reRenderMath();
    },
    downloadJson() {
      const date = new Date();
      const json = JSON.stringify(
        {
          timeCreated: date,
          cards: this.cards,
        },
        null,
        4
      );
      const blob = new Blob([json], { type: "application/json;charset=utf8" });
      download(
        blob,
        `pogotowie_${date.getDate()}_${date.getMonth() +
          1}_${date.getHours()}_${date.getMinutes()}.json`
      );
    },
    getDataFromUploadedJson(evt) {
      var reader = new FileReader();
      reader.onload = async () => {
        await this.replaceCards(JSON.parse(reader.result).cards);
        this.creatorAutoFixUp();
        this.reRenderMath();
      };
      reader.readAsText(evt.target.files[0]);
      this.creatorWelcomeDialog = false;
    },
    publish() {
      this.publishDialog = false;
      this.waitSnackbar = true;

      let date = new Date();
      let ms = date.getTime();
      let id = ms.toString(16).toUpperCase();

      this.code = id;

      db.collection("exercises")
        .doc(id)
        .set({
          cards: this.cards,
        })
        .catch(() => {
          alert("Wystąpił błąd!");
        })
        .then(() => {
          let ref = db.collection("weeks").doc(this.chosenWeek);

          ref.get().then((doc) => {
            let taskList = doc.data().taskList;

            let lastId = 0;

            if (taskList.length == 0) {
              lastId = -1;
            } else {
              lastId = taskList[taskList.length - 1].id;
            }

            ref
              .update({
                taskList: firebase.firestore.FieldValue.arrayUnion({
                  id: lastId + 1,
                  title: this.chosenTitle,
                  description: this.chosenSubtitle,
                  key: id,
                }),
              })
              .then(() => {
                this.successSnackbar = true;
                this.waitSnackbar = false;
              });
          });
        });
    },
    openEqualationEditor() {
      // const settings = "height=300,width=300,resizable";
      window.open(
        "https://nieosiolek.epizy.com/math-editor/pogotowie.html",
        "popUpWindow",
        "height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
      );
    },
  },
  components: { AddClosedExercise, AddInfo, CodeEditor },
  computed: mapState(["cards", "currentCard"]),
  data() {
    return {
      showOnceDialog: false,
      publishStep: 1,
      publishDialog: false,
      chosenWeek: "",
      weeks: [],
      chosenTitle: "",
      chosenSubtitle: "",
      successSnackbar: false,
      code: "",
      waitSnackbar: false,
      editCodeDialog: false,
      editCodeDialogCode: "",
      editCodeDialogCardId: 0,
      editCodeDialogExerciseIndex: 0,
      creatorWelcomeDialog: true,
    };
  },
  mounted() {
    this.changeNavVisibility(false);
    window.addEventListener("beforeunload", function(e) {
      var confirmationMessage =
        "It looks like you have been editing something. " +
        "If you leave before saving, your changes will be lost.";

      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    });

    db.collection("weeks")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.weeks.push(doc.id);
        });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });

    this.pushCreatorCard();
    this.reRenderMath();
  },
  destroyed() {
    this.changeNavVisibility(true);
  },
};
</script>

<style>
.border-card {
  border: 1px solid grey !important;
}
.fixed {
  position: fixed;
  z-index: 100;
}
.xlmargin {
  margin-top: 80px;
}
</style>
