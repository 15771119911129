<template>
  <div id="wrap">
    <v-dialog v-model="dialog" width="1200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Karta z teorią
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2 mb-6">
          Informacja do zadań
        </v-card-title>

        <v-card-text>
          <v-btn outlined @click="uploadDialog = true" class="mb-2">
            Dołącz obraz</v-btn
          >
          <v-btn outlined @click="linkDialog = true" class="mb-2 ml-2">
            Dołącz link/plik</v-btn
          >
          <add-math-record-button />
          <jodit-editor
            v-model="content"
            :buttons="buttons"
            :extra-buttons="customButtons"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog"> Zapisz </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- UPLOAD -->
    <v-dialog v-model="uploadDialog" max-width="700px">
      <v-card>
        <v-card-title>Dołącz obraz</v-card-title>
        <v-card-text>
          <input type="file" @change="upload" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Przesyłam...
          <v-progress-linear
            :value="uploadValue"
            class="mt-2"
            color="white"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- LINK -->
    <v-dialog v-model="linkDialog" max-width="700px">
      <v-card>
        <v-card-title>Dołącz link/plik</v-card-title>
        <v-card-text>
          <!-- LINK -->
          <span class="overline">Link</span>
          <v-text-field
            label="Adres WWWW"
            v-model="link"
            placeholder="https://testpogotowia.web.app/siema"
            outlined
          ></v-text-field>

          <v-text-field
            label="Tekst do wyświetlenia"
            v-model="linkText"
            placeholder="Link do zadania"
            outlined
          ></v-text-field>

          <v-btn @click="insertLink" class="mb-2" elevation="0"
            ><v-icon left> mdi-link </v-icon>Wstaw</v-btn
          >

          <v-divider></v-divider>

          <!-- FILE -->
          <div class="mt-4">
            <span class="overline">Plik</span><br />
            <input type="file" @change="uploadLink" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "jodit/build/jodit.min.css";
import { JoditEditor } from "jodit-vue";
import { mapMutations, mapState } from "vuex";
import firebase from "firebase";
import AddMathRecordButton from "./AddMathRecordButton.vue";

export default {
  components: {
    JoditEditor,
    AddMathRecordButton,
  },
  data() {
    return {
      content: "",
      dialog: false,
      uploadDialog: false,
      linkDialog: false,
      link: "",
      linkText: "",
      fileData: null,
      loading: false,
      uploadValue: 0,
      buttons: [
        "source",
        "|",
        "bold",
        "underline",
        "italic",
        "|",
        "table",
        "|",
        "ol",
        "ul",
        "|",
        "superscript",
        "subscript",
        "|",
        "brush",
        "|",
      ],
      customButtons: [
        {
          name: "header1",
          iconURL:
            "https://firebasestorage.googleapis.com/v0/b/flashcards-bc887.appspot.com/o/p1.svg?alt=media&token=fe662dad-ed8a-42d3-a074-94a9c221091d",
          exec: function(editor) {
            editor.selection.insertHTML(
              '<p class="headline">Tutaj wprowadź nagłówek pierwszego stopnia</p>'
            );
          },
        },
        {
          name: "header2",
          iconURL:
            "https://firebasestorage.googleapis.com/v0/b/flashcards-bc887.appspot.com/o/p2.svg?alt=media&token=e2b799d7-e017-4610-bb77-8f23e7083a65",
          exec: function(editor) {
            editor.selection.insertHTML(
              "<p><b><u>Tutaj wprowadź nagłówek drugiego stopnia</u></b></p>"
            );
          },
        },
      ],
    };
  },
  computed: mapState(["currentCard", "currentExercise"]),
  methods: {
    ...mapMutations(["pushCreatorExercise"]),
    // upload
    upload(event) {
      let date = new Date();
      let ms = date.getTime();
      let id = ms.toString(16).toUpperCase();

      this.uploadDialog = false;
      this.loading = true;
      this.uploadValue = 0;
      this.fileData = event.target.files[0];
      const storageRef = firebase
        .storage()
        .ref(id)
        .put(this.fileData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          this.loading = false;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.content =
              this.content + `<img src="${url}" alt="Exercise image">`;
            this.loading = false;
          });
        }
      );
    },
    // link upload
    uploadLink(event) {
      let date = new Date();
      let ms = date.getTime();
      let id = ms.toString(16).toUpperCase() + "DOC";

      this.linkDialog = false;
      this.loading = true;
      this.uploadValue = 0;
      this.fileData = event.target.files[0];
      const storageRef = firebase
        .storage()
        .ref(id)
        .put(this.fileData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          this.loading = false;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.content =
              this.content +
              `<p><a href="${url}" target="_blank">Pobierz plik</a></p>`;
            this.loading = false;
          });
        }
      );
    },

    closeDialog() {
      let obj = {
        cardID: this.currentCard,
        exercise: {
          exerciseTitle: this.content,
          exerciseType: "info",
          id: this.currentExercise + 1,
        },
      };
      this.pushCreatorExercise(obj);

      // clear data
      (this.content = ""), (this.answers = []), (this.correct = false);
      this.dialog = false;
      this.currentExercise++;

      this.$emit("finish");
    },
    pushAnswer() {
      this.answers.push({
        content: this.answerContent,
        isCorrect: this.correct,
      });
    },
    insertLink() {
      this.content =
        this.content +
        `<p><a href="${this.link}" target="_blank">${this.linkText}</a></p>`;
      this.linkDialog = false;
    },
  },
};
</script>

<style>
#wrap {
  display: inline;
}
</style>
