<template>
  <v-btn outlined @click="startEditor" class="mb-2 ml-2">
    Dodaj zapis matematyczny</v-btn
  >
</template>

<script>
export default {
  methods: {
    startEditor() {
      window.open(
        "https://nieosiolek.epizy.com/math-editor/pogotowie.html",
        "mathPopUp",
        "height=500,width=600"
      );
    },
  },
};
</script>

<style></style>
