<template>
  <div id="wrap">
    <v-dialog v-model="dialog" width="1200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> Zadanie </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2 mb-6">
          Zadanie
        </v-card-title>

        <v-card-text>
          <v-btn
            outlined
            @click="
              uploadTo = 'content';
              uploadDialog = true;
            "
            class="mb-2"
          >
            Dołącz obraz</v-btn
          >

          <v-btn
            outlined
            @click="
              linkTo = 'content';
              linkDialog = true;
            "
            class="mb-2 ml-2"
          >
            Dołącz link/plik</v-btn
          >

          <add-math-record-button />

          <jodit-editor
            v-model="content"
            :buttons="buttons"
            :extra-buttons="customButtons"
          />

          <v-list>
            <v-list-item v-for="(answer, index) in answers" :key="index">
              <v-list-item-icon>
                <v-icon class="ml-2 green--text" v-if="answer.isCorrect">
                  mdi-check-circle
                </v-icon>
                <v-icon class="ml-2 red--text" v-if="!answer.isCorrect">
                  mdi-cancel
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-html="answer.content"
              ></v-list-item-content>
            </v-list-item>
          </v-list>

          <div v-if="type == ''">
            <p class="caption">Wybierz rodzaj zadania:</p>
            <v-btn depressed @click="type = 'select'">Zamknięte</v-btn>
            <v-btn depressed class="ml-2" @click="type = 'open'">Otwarte</v-btn>
            <p class="caption">Lub wybierz szablon</p>
            <template v-for="template in templates">
              <v-btn
                :key="template.name"
                @click="loadTemplate(template.id)"
                depressed
                class="ml-2"
                >{{ template.name }}</v-btn
              >
            </template>
          </div>
          <!-- FOR OPEN EXERCISES -->
          <div v-if="type == 'open'">
            <p class="subtitle-1">Klucz odpowiedzi:</p>
            <v-btn
              outlined
              @click="
                uploadTo = 'key';
                uploadDialog = true;
              "
              class="mb-2"
            >
              Dołącz obraz</v-btn
            >

            <v-btn
              outlined
              @click="
                linkTo = 'key';
                linkDialog = true;
              "
              class="mb-2 ml-2"
            >
              Dołącz link/plik</v-btn
            >

            <add-math-record-button />

            <jodit-editor
              v-model="key"
              :buttons="buttons"
              :extra-buttons="customButtons"
            />

            <v-text-field
              type="number"
              v-model="points"
              label="Liczba punktów"
              outlined
              class="mt-3"
            ></v-text-field>
          </div>

          <!-- FOR CLOSE EXERCISE -->
          <v-btn
            color="primary"
            class="ma-2"
            v-if="type == 'select'"
            dark
            @click="answerDialog = true"
            >Dodaj odpowiedź</v-btn
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clear"> Wyczyść </v-btn>
          <v-btn color="primary" text @click="closeDialog"> Zapisz </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="answerDialog" max-width="700px">
      <v-card>
        <v-card-title> Dodaj odpowiedź</v-card-title>
        <v-card-text>
          <v-btn
            outlined
            @click="
              uploadTo = 'answerContent';
              uploadDialog = true;
            "
            class="mb-2"
          >
            Dołącz obraz</v-btn
          >
          <add-math-record-button />
          <jodit-editor
            v-model="answerContent"
            :buttons="buttons"
            :extra-buttons="customButtons"
          />
          <v-checkbox
            v-model="correct"
            label="To jest poprawna odpowiedź"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="pushAnswer"> Zapisz </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" max-width="700px">
      <v-card>
        <v-card-title>Dołącz obraz</v-card-title>
        <v-card-text>
          <input type="file" @change="upload" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Przesyłam...
          <v-progress-linear
            :value="uploadValue"
            class="mt-2"
            color="white"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- LINK -->
    <v-dialog v-model="linkDialog" max-width="700px">
      <v-card>
        <v-card-title>Dołącz link/plik</v-card-title>
        <v-card-text>
          <!-- LINK -->
          <span class="overline">Link</span>
          <v-text-field
            label="Adres WWWW"
            v-model="link"
            placeholder="https://testpogotowia.web.app/siema"
            outlined
          ></v-text-field>

          <v-text-field
            label="Tekst do wyświetlenia"
            v-model="linkText"
            placeholder="Link do zadania"
            outlined
          ></v-text-field>

          <v-btn @click="insertLink" class="mb-2" elevation="0"
            ><v-icon left> mdi-link </v-icon>Wstaw</v-btn
          >

          <v-divider></v-divider>

          <!-- FILE -->
          <div class="mt-4">
            <span class="overline">Plik</span><br />
            <input type="file" @change="uploadLink" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "jodit/build/jodit.min.css";
import { JoditEditor } from "jodit-vue";
import { mapMutations, mapState } from "vuex";
import firebase from "firebase";
import AddMathRecordButton from "./AddMathRecordButton.vue";
import templates from "@/templates";

export default {
  components: {
    JoditEditor,
    AddMathRecordButton,
  },
  props: [""],
  data() {
    return {
      templates: templates,
      radioGroup: "",
      key: "",
      linkTo: "",
      content: "",
      dialog: false,
      answerDialog: false,
      answerContent: "",
      linkDialog: false,
      link: "",
      linkText: "",
      correct: false,
      answers: [],
      uploadDialog: false,
      fileData: null,
      loading: false,
      uploadValue: 0,
      uploadTo: "",
      type: "",
      points: 0,
      buttons: [
        "source",
        "|",
        "bold",
        "underline",
        "italic",
        "|",
        "table",
        "|",
        "ol",
        "ul",
        "|",
        "superscript",
        "subscript",
        "|",
        "brush",
        "|",
      ],
      customButtons: [
        {
          name: "header1",
          iconURL:
            "https://firebasestorage.googleapis.com/v0/b/flashcards-bc887.appspot.com/o/p1.svg?alt=media&token=fe662dad-ed8a-42d3-a074-94a9c221091d",
          exec: function(editor) {
            editor.selection.insertHTML(
              '<p class="headline">Tutaj wprowadź nagłówek pierwszego stopnia</p>'
            );
          },
        },
        {
          name: "header2",
          iconURL:
            "https://firebasestorage.googleapis.com/v0/b/flashcards-bc887.appspot.com/o/p2.svg?alt=media&token=e2b799d7-e017-4610-bb77-8f23e7083a65",
          exec: function(editor) {
            editor.selection.insertHTML(
              "<p><b><u>Tutaj wprowadź nagłówek drugiego stopnia</u></b></p>"
            );
          },
        },
      ],
    };
  },
  computed: mapState(["currentCard", "currentExercise"]),
  methods: {
    ...mapMutations(["pushCreatorExercise"]),
    loadTemplate(i) {
      const template = this.templates[i];
      console.log(template);
      this.answers = template.exerciseAnswers;
      this.content = template.exerciseTitle;
      this.type = template.exerciseType;
    },
    insertLink() {
      if (this.linkTo == "content") {
        this.content =
          this.content +
          `<p><a href="${this.link}" target="_blank">${this.linkText}</a></p>`;
      }

      if (this.linkTo == "key") {
        this.key =
          this.key +
          `<p><a href="${this.link}" target="_blank">${this.linkText}</a></p>`;
      }

      this.linkDialog = false;
    },
    // upload
    upload(event) {
      let date = new Date();
      let ms = date.getTime();
      let id = ms.toString(16).toUpperCase();

      this.uploadDialog = false;
      this.loading = true;
      this.uploadValue = 0;
      this.fileData = event.target.files[0];
      const storageRef = firebase
        .storage()
        .ref(id)
        .put(this.fileData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          this.loading = false;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            if (this.uploadTo == "content") {
              this.content =
                this.content + `<img src="${url}" alt="Exercise image">`;
            }
            if (this.uploadTo == "answerContent") {
              this.answerContent =
                this.answerContent + `<img src="${url}" alt="Answer image">`;
            }
            if (this.uploadTo == "key") {
              this.key = this.key + `<img src="${url}" alt="Key image">`;
            }
            this.loading = false;
          });
        }
      );
    },
    // link upload
    uploadLink(event) {
      let date = new Date();
      let ms = date.getTime();
      let id = ms.toString(16).toUpperCase() + "DOC";

      this.linkDialog = false;
      this.loading = true;
      this.uploadValue = 0;
      this.fileData = event.target.files[0];
      const storageRef = firebase
        .storage()
        .ref(id)
        .put(this.fileData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          this.loading = false;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            if (this.linkTo == "content") {
              this.content =
                this.content +
                `<p><a href="${url}" target="_blank">Pobierz plik</a></p>`;
            }

            if (this.linkTo == "key") {
              this.key =
                this.key +
                `<p><a href="${url}" target="_blank">Pobierz plik</a></p>`;
            }

            this.loading = false;
          });
        }
      );
    },

    closeDialog() {
      let obj = {};

      if (this.answers.length == 0 && this.type != "open") {
        alert("Proszę dodać odpowiedzi!");
        return;
      }

      // for close exercise
      function isCorrect(value) {
        return value.isCorrect == true;
      }

      if (this.type == "select") {
        let type = "once";

        let answersToFilter = this.answers;
        if (answersToFilter.filter(isCorrect).length != 1) {
          type = "multiple";
        }

        obj = {
          cardID: this.currentCard,
          exercise: {
            exerciseTitle: this.content,
            exerciseType: type,
            id: this.currentExercise + 1,
            exerciseAnswers: this.answers,
          },
        };
      }

      // for open exercise
      if (this.type == "open") {
        obj = {
          cardID: this.currentCard,
          exercise: {
            exerciseTitle: this.content,
            exerciseType: "open",
            id: this.currentExercise + 1,
            exerciseAnswers: this.key,
            points: parseInt(this.points),
          },
        };
      }

      this.pushCreatorExercise(obj);

      this.clear();
      this.dialog = false;
      this.$emit("finish");
    },
    pushAnswer() {
      this.answers.push({
        content: this.answerContent,
        isCorrect: this.correct,
      });
    },
    clear() {
      // clear data
      (this.content = ""), (this.answers = []), (this.correct = false);
      this.currentExercise++;
      this.type = "";
    },
  },
};
</script>

<style>
#wrap {
  display: inline;
}
.exercise-table,
.exercise-table td,
.exercise-table th {
  border: 1px solid;
  font-family: "Times New Roman", Times, serif;
  color: black;
}

.exercise-table td {
  height: 50px;
  padding: 10px;
}

.exercise-table {
  width: 100%;
  border-collapse: collapse;
}

.exercise-table .answer {
  width: 50px;
  text-align: center;
  font-weight: bold;
}

.padding-table td {
  padding: 20px;
}
</style>
