<template>
  <prism-editor
    class="my-editor height-300"
    v-model="inputVal"
    :highlight="highlighter"
  ></prism-editor>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-okaidia.min.css"; // import syntax highlighting styles

export default {
  components: { PrismEditor },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
  },
  props: ["value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
.my-editor {
  background: #272822;
  color: white;
  padding: 20px;
}
</style>
